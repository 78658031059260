export const LABELS = {
  noAssignees: 'Assignee',
  assignees: 'Assignees',
  assigneesHeader: (maxCount: number) => `Assign up to ${maxCount} people to this issue`,
  cantEditItems: (itemType: string) => `Cannot edit ${itemType} right now`,
  noLabels: 'Label',
  labels: 'Labels',
  labelsHeader: 'Apply labels to this issue',
  editLabels: 'Edit labels',
  noProjects: 'Project',
  noMilestones: 'Milestone',
  milestonesHeader: 'Set milestone',
  issueHeader: 'Choose issue',
  noIssues: 'Issue',
  noIssueTypes: 'Issue Type',
  filterOrganizations: 'Filter organizations',
  organizationLabel: 'Organization',
  selectAssignees: 'Select assignees',
  selectMilestones: 'Select milestone',
  selectIssue: 'Select issue',
  selectIssueTypes: 'Select issue type',
  typesHeader: 'Select issue type',
  typesSubHeader: 'Learn more or',
  feedback: 'give feedback.',
  selectProjects: 'Select projects',
  selectLabels: 'Select labels',
  selectRepository: 'Select repository',
  selectBranch: 'Select a branch',
  selectPr: 'Select a pull request',
  searchPr: 'Search pull requests',
  maxPRsLinkingWarning: 'You may only link 10 pull requests to this issue across all repositories.',
  filterUsers: (title: string) => `Filter ${title.toLowerCase()}s`,
  filterMilestones: 'Filter milestones',
  filterIssueTypes: 'Filter issue types',
  filterProjects: 'Filter projects',
  noPullRequest: 'no pull request',
  milestones: {
    pastDue: 'Past due by',
    milestoneClosed: 'Closed',
    milestoneDue: 'Due by',
    noDueDate: 'No due date',
    progressPercentage: (progress: number) => (progress ? `, ${Math.round(progress)}% complete` : ', not started'),
  },
  testIds: {
    relativeTimeDescription: 'relative-time-description',
  },
  getNumberOfSelectedPrsLabel: (count: number) =>
    count === 1 ? `${count} pull request selected` : `${count} pull requests selected`,
  projectItemsLimitReached:
    'This project reached the maximum number of items allowed currently (1200 items). To add more, please archive or delete existing items from it.',
  closeButton: 'Close dialog',
  projectItemsLimitReachedDialogLabel: 'Project items limit reached dialog',
  projectItemsLimitReachedDialogTitle: 'Project limits reached',
  projectItemsLimitReachedDialogMessage:
    'One or more of the projects you selected reached the maximum number of items allowed currently (1200 items). To add more, please archive or delete existing items from the selected project(s).',
}
